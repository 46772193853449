import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum SnackbarType {
  error = 'error-snack',
  success = 'success-snack',
  info = 'info-snack',
  warning = 'warning-snack',
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private readonly snackbar = inject(MatSnackBar);

  showSnackbar(message: string, type: SnackbarType = SnackbarType.success, action = 'Ok') {
    this.snackbar.open(message, action, {
      duration: 5000,
      direction: 'ltr',
      verticalPosition: 'top',
      panelClass: type,
    });
  }
}
